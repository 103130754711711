let prevScrollpos = window.pageYOffset;
const toggleNav = document.querySelector(".toggle_btn")
const menuList = document.querySelector((".menu_list"))
const main = document.querySelector((".main"))
const footer = document.querySelector((".footer"))
const sub_menu_list = document.querySelector(".sub-menu_list")
const sub_menu_cards = document.querySelector("#sub-menu_cards")

window.onscroll = function() {
    toggleNav.setAttribute('aria-expanded', false)
    main.classList.remove('blur')
    footer.classList.remove('blur')
    menuList.classList.remove('opened')
    let currentScrollPos = window.pageYOffset
    let header = document.querySelector("header")
    if (prevScrollpos > currentScrollPos || currentScrollPos < 100) {
        header.className && header.classList.remove('hide')
    } else {
        header.className && header.classList.add('hide')
    }
    prevScrollpos = currentScrollPos
}

toggleNav.addEventListener('click', () => {
    let exp = "true" === toggleNav.getAttribute('aria-expanded')
    toggleNav.setAttribute('aria-expanded', !exp)
    menuList.classList.toggle('opened')
    if (!exp){
        main.classList.add('blur')
        footer.classList.add('blur')
    } else {
        main.classList.remove('blur')
        footer.classList.remove('blur')
    }
})

sub_menu_cards.addEventListener('click', () => {
    sub_menu_list.classList.toggle('opened')
})
// document.addEventListener('click', e => {
//     console.log(e.target);
//     sub_menu_list.classList.remove('opened')
// })

let select = document.getElementById('language')
select.addEventListener("change", () => {
    let option = select.options[select.selectedIndex]
    let currentLang = location.pathname.match(/^\/[a-z]{2}\//)
    if (currentLang) {
        window.location.href = location.pathname.replace(/^\/[a-z]{2}\//, `/${option.value}/`)
    } else {
        window.location.href = `/${option.value}${location.pathname}`
    }
})

// Отримати значення параметра "ref" з URL
const urlParams = new URLSearchParams(window.location.search);
const refParam = urlParams.get("ref");

// Перевірити, чи параметр "ref" не порожній і не дорівнює null
if (refParam) {
    // Зберегти значення параметра "ref" в localStorage
    localStorage.setItem("ref", refParam);
}
